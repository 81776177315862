<template>
  <ion-menu content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>Green Pastures</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>

        <router-link class="link-selah" :to="`/`">
          <ion-item detail='true'>
            <ion-label>Ikaze</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/about`">
          <ion-item detail='true'>
            <ion-label>Twebwe</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/ibibazo`">
          <ion-item detail='true'>
            <ion-label>Ibibazo</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/rondera`">
          <ion-item detail='true'>
            <ion-label>Rondera</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/shigikira`">
          <ion-item detail='true'>
            <ion-label>Shigikira</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/sangiza`">
          <ion-item detail='true'>
            <ion-label>Sangiza abandi</ion-label>
          </ion-item>
        </router-link>

        <ion-item>
          <!-- <ion-label>Impinduro 1.0.1</ion-label> --> <!-- ios -->
          <ion-label>Impinduro 1.0.2</ion-label> <!-- android -->
        </ion-item>

        <ion-item>
          <ion-label>Yakozwe na <a href="https://www.egeratech.com">egeraTech</a></ion-label>
        </ion-item>

      </ion-list>

      <br>
      <br>

    </ion-content>
  </ion-menu>



  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Ibibazo</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">



      <div class="card-igisomwa">
        <h3>Ibibazo bikunda kubazwa</h3>
        <br>
        <div v-if="loader && this.$store.state.ibibazo == 0">
          <br><br><br>
          <OurLoading />
        </div>

        <!-- <div v-for="(ikibazo, index) in this.$store.state.ibibazo" :key="index">
          <h2>{{ ikibazo.ikibazo }}</h2>
          <pre>{{ ikibazo.inyishu }}</pre>
        </div> -->

        <div v-for="(ikibazo, index) in this.$store.state.ibibazo" :key="index" class="faq-item">
          <h2 class="question" @click="toggleAnswer(index)">{{ ikibazo.ikibazo }}</h2>
          <div v-show="ikibazo.showAnswer" class="answer">
            <p>{{ ikibazo.inyishu }}</p>
          </div>
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons } from '@ionic/vue';
import OurLoading from '@/components/OurLoading.vue'

export default {
  name: 'AboutView',
  components: {
    IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons, OurLoading
  },
  data() {
    return {
      loader: true
    }
  },
  methods: {
    loadibibazo() {
      this.axios.get(this.$store.state.baseUrl + 'ibibazo/').then(
        response => {
          this.$store.state.ibibazo = response.data.results;
          this.loader = false;
        }
      ) //.finally(this.loadTwebwe())
    },
    toggleAnswer(index) {
      this.$store.state.ibibazo[index].showAnswer = !this.$store.state.ibibazo[index].showAnswer;
    },

  },
  mounted() {
    this.loadibibazo();
  },
}
</script>

<style>
.faq-item {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.answer {
  display: none;
}


/* Show/hide functionality for the answers */
.faq-item:hover .answer {
  display: block;
}
</style>