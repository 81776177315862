<template>
  <ion-menu content-id="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-title >Green Pastures</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>

      <router-link class="link-selah" :to="`/`">
        <ion-item detail='true'>
          <ion-label>Ikaze</ion-label>
      </ion-item>
    </router-link>

      <router-link class="link-selah" :to="`/about`">
          <ion-item detail='true'>
            <ion-label>Twebwe</ion-label>
          </ion-item>
      </router-link>

      <router-link class="link-selah" :to="`/ibibazo`">
          <ion-item detail='true'>
            <ion-label>Ibibazo</ion-label>
          </ion-item>
      </router-link>

      <router-link class="link-selah" :to="`/rondera`">
          <ion-item detail='true'>
            <ion-label>Rondera</ion-label>
          </ion-item>
      </router-link>

      <router-link class="link-selah" :to="`/shigikira`">
          <ion-item detail='true'>
            <ion-label>Shigikira</ion-label>
          </ion-item>
      </router-link>
      
      <router-link class="link-selah" :to="`/sangiza`">
          <ion-item detail='true'>
            <ion-label>Sangiza abandi</ion-label>
          </ion-item>
      </router-link>

      <ion-item>
          <!-- <ion-label>Impinduro 1.0.1</ion-label> --> <!-- ios -->
          <ion-label>Impinduro 1.0.2</ion-label> <!-- android -->
        </ion-item>

        <ion-item>
          <ion-label>Yakozwe na <a href="https://www.egeratech.com">egeraTech</a></ion-label>
        </ion-item>
        
    </ion-list>

        <br>
        <br>

  </ion-content>
</ion-menu>



<ion-page id="main-content">

<ion-header :translucent="true">
  <ion-toolbar>
    <ion-title>Intangamarara</ion-title>
    <ion-buttons slot="end">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content :fullscreen="true">
    <br>
    <OurButton @click="beforeRouteEnter()">Subira inyuma</OurButton>

    <div v-if="loader">
        <br><br>
        <OurLoading />
    </div>

    <div v-else>
        <div class="card-parent-videwo">
            <div class="card-videwo">
                <h2>Intangamarara y'igitabo: {{intangamarara.igitabo}}</h2>
                <p>{{intangamarara.message}}</p>
                <iframe :src="intangamarara.youtube_link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</ion-content>
</ion-page>
</template>


<script>
import {   IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons } from '@ionic/vue';

import OurButton from '@/components/OurButton.vue'
import OurLoading from '@/components/OurLoading.vue'

    export default{
        name:'intangamarara-igitabo',
        components: {
          IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons, OurButton, OurLoading
        },
        data(){
            return{
                loader: true,
                intangamarara: [],
            }
        },
        methods: {
            beforeRouteEnter() {
                // last path/route
                this.$router.go(-1)
            }
        },
        mounted(){
            this.loader = true;
            this.axios.get(this.$store.state.baseUrl + 'intangamarara/'+ this.$route.params.id + '/')
            .then(
                resp => {
                console.log(resp)
                this.intangamarara = resp.data
                this.loader = false
                }).catch(error => {console.log(error)
                });
            this.topFunction()
        },
    }
</script>