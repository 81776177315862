<template>
  <ion-menu content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>Green Pastures</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>

        <router-link class="link-selah" :to="`/`">
          <ion-item detail='true'>
            <ion-label>Ikaze</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/about`">
          <ion-item detail='true'>
            <ion-label>Twebwe</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/ibibazo`">
          <ion-item detail='true'>
            <ion-label>Ibibazo</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/rondera`">
          <ion-item detail='true'>
            <ion-label>Rondera</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/shigikira`">
          <ion-item detail='true'>
            <ion-label>Shigikira</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/sangiza`">
          <ion-item detail='true'>
            <ion-label>Sangiza abandi</ion-label>
          </ion-item>
        </router-link>

        <ion-item>
          <!-- <ion-label>Impinduro 1.0.1</ion-label> --> <!-- ios -->
          <ion-label>Impinduro 1.0.2</ion-label> <!-- android -->
        </ion-item>

        <ion-item>
          <ion-label>Yakozwe na <a href="https://www.egeratech.com">egeraTech</a></ion-label>
        </ion-item>

      </ion-list>

      <br>
      <br>

    </ion-content>
  </ion-menu>



  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Twebwe</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">


      <div v-if="loader && this.$store.state.twebwe == 0">
        <br><br><br>
        <OurLoading />
      </div>

      <div @click="close" v-if="success">
        <OurSuccess />
      </div>

      <div v-for="(item, index) in this.$store.state.twebwe" :key="index">
        <div class="card-igisomwa">
          <h2>Kahise kacu</h2>
          <p>{{ item.kahise_kacu }}</p>
          <iframe :src="item.kahise_kacu_youtube_link" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <h2>Ukwo dusoma</h2>
          <p>{{ item.ukwo_dusoma }}</p>
          <iframe :src="item.ukwo_dusoma_youtube_link" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <br>
          <br>

          <p>
            <a :href="item.ivyo_twizera_littleFlockBurundi_link">
              <OurButton>Soma ngaha ivyo twizera</OurButton>
            </a>
          </p>

          <h2>Kwiyandikisha mubiga ivyizerwa</h2>
          <form class="from-ivyizerwa" @submit.prevent="save_MemberInDoctrine">
            <input type="text" placeholder="Izina" v-model="form.izina" required>
            <input type="text" placeholder="Amatazirano" v-model="form.amatazirano" required>

            <input type="number" placeholder="Telefone" v-model="form.phone" required>
            <input type="text" placeholder="Aho usengera" v-model="form.aho_asengera">
            <input type="text" placeholder="Umwaka (ak: 2024)" v-model="form.promotion">
            <input type="text" placeholder="Aho uba" v-model="form.aho_aba">


            <button type="submit" class="submit">Rungika</button>
          </form>

        <br>

        <ion-list>
          <ion-item>
            <div class="socio">
              <a href="https://www.facebook.com/profile.php?id=100064842694500"><i class="icon-facebook"
                  aria-hidden="true"></i></a>
              <a href="https://twitter.com/BurundiFlock"><i class="icon-twitter"></i></a>
              <a href="https://www.youtube.com/channel/UCbjZ4CtsHcdz5E8-HatdAhg/videos"><i class="icon-youtube"></i></a>
              <a href="https://www.instagram.com/littleflockburundi/"><i class="icon-instagram"></i></a>
            </div>
          </ion-item>
        </ion-list>
      </div>
    </div>


    </ion-content>
  </ion-page>
</template>


<script>
import { IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons } from '@ionic/vue';
import OurButton from '@/components/OurButton.vue'
import OurSuccess from '@/components/OurSuccess.vue'
import OurLoading from '@/components/OurLoading.vue'


export default {
  name: 'AboutView',
  components: {
    OurButton, OurSuccess, OurLoading,
    IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons
  },
  data() {
    return {
      loader: true,
      success: false,
      form: {
        izina: '',
        amatazirano: '',
        phone: '',
        aho_asengera: '',
        promotion: '',
        aho_aba: '',
      },

    }
  },
  methods: {
    save_MemberInDoctrine() {
      console.log(this.form)
      this.axios.post(this.$store.state.baseUrl + 'abiga-ivyizerwa/', this.form)
        .then(response => {
          console.log(response)
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 2500);
          this.form = {}
        }).catch(err => {
          console.log(err)
        })
      this.topFunction()
    },
    loadTwebwe() {
      this.axios.get(this.$store.state.baseUrl + 'twebwe/').then(
        response => {
          this.$store.state.twebwe = response.data.results;
          this.loader = false;
        }
      )
    },


  },
  // created(){
  mounted() {
    this.loadTwebwe();
  },
}
</script>

<style></style>