<template>
    <div class="backdrop">
        <span class="loader"></span>
    </div>
</template>

<style>
.loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    background: #4a9b51;
    box-sizing: border-box;
    animation: flipX 1s linear infinite;
    margin: 150px auto;

}


@keyframes flipX {
    0% {
        transform: perspective(200px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(200px) rotateX(-180deg) rotateY(0deg);
    }
    100% {
        transform: perspective(200px) rotateX(-180deg) rotateY(-180deg);
    }
}
    
</style>