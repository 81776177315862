<template>
    <div class="backdrop">
        <div class="modal">
            <i class="icon-done" aria-hidden="true"></i>
            <h3 class="vyakunze">Vyakunze</h3>
        </div>
    </div>
</template>

<style>
.modal {
    margin: 100px auto;
    width: 300px;
    height: 150px;
    background: #fff;
    color: #4a9b51;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
}
.backdrop {
    top: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;

} 
</style>