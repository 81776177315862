<template>
  <ion-menu content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>Green Pastures</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>

        <router-link class="link-selah" :to="`/`">
          <ion-item detail='true'>
            <ion-label>Ikaze</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/about`">
          <ion-item detail='true'>
            <ion-label>Twebwe</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/ibibazo`">
          <ion-item detail='true'>
            <ion-label>Ibibazo</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/rondera`">
          <ion-item detail='true'>
            <ion-label>Rondera</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/shigikira`">
          <ion-item detail='true'>
            <ion-label>Shigikira</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/sangiza`">
          <ion-item detail='true'>
            <ion-label>Sangiza abandi</ion-label>
          </ion-item>
        </router-link>

        <ion-item>
          <!-- <ion-label>Impinduro 1.0.1</ion-label> --> <!-- ios -->
          <ion-label>Impinduro 1.0.2</ion-label> <!-- android -->
        </ion-item>

        <ion-item>
          <ion-label>Yakozwe na <a href="https://www.egeratech.com">egeraTech</a></ion-label>
        </ion-item>

      </ion-list>

      <br>
      <br>

    </ion-content>
  </ion-menu>



  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Green Pastures</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">


      <div v-if="loader && this.$store.state.igisomwaUnomusi == 0">
        <br><br><br>
        <OurLoading />
      </div>

      <div v-if="loader == false && this.$store.state.igisomwaUnomusi == 0">
        <br>
        <br>
        <div class="card-igisomwa">
          <h3>Unomusi ntagisomwa gitangwa,</h3>
          <h3>ariko urashobora kurondera igisomwa ushaka</h3>
          <button class="submit" @click="rondera">Rondera</button>
        </div>
      </div>

      <div v-for="(intangamarara, index) in this.$store.state.igisomwaUnomusi" :key="index">

        <div class="card-igisomwa">
          <iframe :src="intangamarara.youtube_link" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <h2>Intangamarara y'igitabo:</h2>
          <h3>{{ intangamarara.igitabo }}</h3>
          <pre>{{ intangamarara.message }}</pre>

          <div v-for="(igisomwa, index) in intangamarara.ibisomwa" :key="index">

            <h3>Itariki {{ moment(igisomwa.post_date).format("DD-MM-YYYY") }}</h3>
            <h3>Indwi ya {{ igisomwa.indwi_ya }}</h3>
            <h3>Umusi wa {{ igisomwa.umusi_wa }}</h3>

            <h2>Igisomwa:</h2>
            <h3>{{ igisomwa.igisomwa }}</h3>

            <h2>Kristo mugisomwa</h2>
            <pre>{{ igisomwa.kristo_mugisomwa }}</pre>

            <h2>Kurimbura</h2>
            <pre>{{ igisomwa.kurimbura }}</pre>

            <h2>Isengesho</h2>
            <pre>{{ igisomwa.isengesho }}</pre>

            <div v-if="igisomwa.amasanamu == 0">
              <!-- <p>Ntasanamu</p> -->
            </div>

            <div v-else>
              <h2>Amasanamu</h2>
              <div v-for="(isanamu, index) in igisomwa.amasanamu" :key="index">
                <img :src="`https://greenpastures.bi${isanamu.isanamu}`" />
                <p>{{ isanamu.description }}</p>
              </div>
            </div>

            <form @submit.prevent="updateAbasomyi(igisomwa.id)">
              <input type="hidden" v-model="abasomyiForm.date">
              <input type="hidden" v-model="abasomyiForm.ndasomye">
              <input type="hidden" v-model="abasomyiForm.igisomwa">

              <!-- <button v-if="ndikondasoma" v-bind:igisomwa_id="igisomwa.id" type="submit" class="submit"> Ndikondasoma </button> -->
              <button v-if="ndikondasoma" type="submit" class="submit"> Ndikondasoma </button>
            </form>

            <ion-item>
              <ion-list>
                <h3 v-if="igisomwa.abasomyi">Abariko barasoma ni {{ igisomwa.abasomyi.length }}</h3>
              </ion-list>
            </ion-item>

            <h2>Inyandiko zakoreshejwe </h2>
            <pre>{{ igisomwa.inyandiko_zakoreshejwe }}</pre>

            <h2>Ubivugako iki??</h2>

            <form class="from-comment" @submit.prevent="save_comment(igisomwa.id)">
              <textarea row="5" placeholder="andika ngaha" v-model="formComment.comment" required></textarea>
              <input type="hidden" v-model="formComment.igisomwa">

              <input type="hidden" v-model="formComment.date">
              <input type="hidden" v-model="formComment.published">


              <button v-bind:igisomwa_id="igisomwa.id" type="submit" class="submit">Rungika</button>
            </form>

            <h2>Ico abandi babivuzeko <span class="comment-date">{{ igisomwa.comments.length }}</span></h2>

            <div v-if="igisomwa.comments == 0">
              <h3>Ntaco abandi barabivugako</h3>
            </div>

            <div v-else>

              <div v-for="(comment, index) in igisomwa.comments" :key="index">
                <ion-list>
                  <ion-item>
                    <p slot="start" class="start"></p>
                    <div class="card-comments">
                      <p class="comment-date">{{ moment(comment.date).format("DD-MM-YYYY") }}</p>
                      <p>{{ comment.comment }}</p>
                    </div>
                  </ion-item>
                </ion-list>
              </div>
            </div>

          </div>
        </div>
      </div>

      <br>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons } from '@ionic/vue';
import moment from 'moment'
import OurLoading from '@/components/OurLoading.vue'


export default {
  name: 'HomeView',
  // props: ['igisomwa_id'],
  components: {
    IonPage, IonContent, OurLoading, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons,
  },
  data() {
    return {
      loader: true,
      moment: moment,
      formComment: {
        comment: '',
        igisomwa: '',
        date: new Date().toISOString().slice(0, 10), //Current date yyyy-mm-dd
        published: 'true'
      },
      ndikondasoma: true,
      abasomyiForm: {
        date: new Date().toISOString().slice(0, 10),
        ndasomye: true,
        igisomwa: '',
      }
    }
  },
  methods: {
    save_comment(id) {
      this.formComment.igisomwa = `${id}` // getting id from loop
      console.log(this.formComment)
      this.axios.post(this.$store.state.baseUrl + 'ico-abandi-babivuzeko/', this.formComment)
        .then(response => {
          console.log(response)
          this.formComment.comment = '';
          this.getIbisomwa();
        }).catch(err => {
          console.log(err)
        })
    },
    updateAbasomyi(id) {
      this.ndikondasoma = false;
      this.abasomyiForm.igisomwa = `${id}` // getting id from loop
      console.log(this.abasomyiForm)
      this.axios.post(this.$store.state.baseUrl + 'abasomyi/', this.abasomyiForm)
        .then(response => {
          console.log(response)
          this.getIbisomwa();
        }).catch(err => {
          console.log(err)
        })
    },
    getIbisomwa() {
      this.axios.get(this.$store.state.baseUrl + 'intangamarara-ibisomwa-unomusi/').then(
        resp => {
          console.log(resp)
          this.$store.state.igisomwaUnomusi = resp?.data?.results;
          this.loader = false;
        }).catch(error => {
          console.log(error)
        })
    },

    rondera() {
      this.$router.push("/rondera")
    },
  },
  mounted() {
    this.getIbisomwa();
  }
}
</script>


<style scoped></style>