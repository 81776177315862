
import axiosMethods from "./axiosMethods"

export default{
    mixins: [axiosMethods],
    data() {
        return {
            
        }
    },

    mounted() {
    
    },
    methods: {
        searchInArray(arrayList, searchText) {
            //Methode pour faire une rechercher dans le tableau
            if(Array.isArray(arrayList) ){
                return arrayList.filter(
                e => JSON.stringify(e)
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
            )
            }
            return arrayList
        },
        topFunction() {
            // go to Top of the page 
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }

        // resultQuery(){
        //     if(this.searchQuery){
        //     return this.resources.filter((item)=>{
        //       return this.searchQuery.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v))
        //     })
        //     }else{
        //       return this.resources;
        //     }
        //   }

    }
}