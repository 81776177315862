<template>
  <ion-menu content-id="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-title >Green Pastures</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>

      <router-link class="link-selah" :to="`/`">
        <ion-item detail='true'>
          <ion-label>Ikaze</ion-label>
      </ion-item>
    </router-link>

      <router-link class="link-selah" :to="`/about`">
          <ion-item detail='true'>
            <ion-label>Twebwe</ion-label>
          </ion-item>
      </router-link>

      <router-link class="link-selah" :to="`/ibibazo`">
          <ion-item detail='true'>
            <ion-label>Ibibazo</ion-label>
          </ion-item>
      </router-link>

      <router-link class="link-selah" :to="`/rondera`">
          <ion-item detail='true'>
            <ion-label>Rondera</ion-label>
          </ion-item>
      </router-link>

      <router-link class="link-selah" :to="`/shigikira`">
          <ion-item detail='true'>
            <ion-label>Shigikira</ion-label>
          </ion-item>
      </router-link>
      
      <router-link class="link-selah" :to="`/sangiza`">
          <ion-item detail='true'>
            <ion-label>Sangiza abandi</ion-label>
          </ion-item>
      </router-link>

      <ion-item>
          <!-- <ion-label>Impinduro 1.0.1</ion-label> --> <!-- ios -->
          <ion-label>Impinduro 1.0.2</ion-label> <!-- android -->
        </ion-item>

        <ion-item>
          <ion-label>Yakozwe na <a href="https://www.egeratech.com">egeraTech</a></ion-label>
        </ion-item>
        
    </ion-list>

        <br>
        <br>

  </ion-content>
</ion-menu>



<ion-page id="main-content">

<ion-header :translucent="true">
  <ion-toolbar>
    <ion-title>Green Pastures</ion-title>
    <ion-buttons slot="end">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content :fullscreen="true">

  <br>
  <br>
  <br>
    <div class="ntagisomwa-na-footer">
        <h3>404</h3>
        <h3>Ntanyishu ihari</h3>
        <h3>:(</h3>
    </div>
    <br>
  <br>
  <br>
  <br>
</ion-content>
</ion-page>
</template>

<script>
import {   IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons } from '@ionic/vue';

export default {
  name: 'AboutView',
  components: {
      IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons
  },
}
</script>