<template>
  <ion-menu content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>Green Pastures</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>

        <router-link class="link-selah" :to="`/`">
          <ion-item detail='true'>
            <ion-label>Ikaze</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/about`">
          <ion-item detail='true'>
            <ion-label>Twebwe</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/ibibazo`">
          <ion-item detail='true'>
            <ion-label>Ibibazo</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/rondera`">
          <ion-item detail='true'>
            <ion-label>Rondera</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/shigikira`">
          <ion-item detail='true'>
            <ion-label>Shigikira</ion-label>
          </ion-item>
        </router-link>

        <router-link class="link-selah" :to="`/sangiza`">
          <ion-item detail='true'>
            <ion-label>Sangiza abandi</ion-label>
          </ion-item>
        </router-link>

        <ion-item>
          <!-- <ion-label>Impinduro 1.0.1</ion-label> --> <!-- ios -->
          <ion-label>Impinduro 1.0.2</ion-label> <!-- android -->
        </ion-item>

        <ion-item>
          <ion-label>Yakozwe na <a href="https://www.egeratech.com">egeraTech</a></ion-label>
        </ion-item>

      </ion-list>

      <br>
      <br>

    </ion-content>
  </ion-menu>



  <ion-page id="main-content">

    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Rondera</ion-title>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">



      <div v-if="loader == true && filteredIntangamarara == 0 || filteredIbisomwa == 0">
        <br><br>
        <OurLoading />
      </div>

      <div v-if="loader == false && filteredIntangamarara == 0 && filteredIbisomwa == 0" class="ntagisomwa-na-footer">
        <h3>Ntanyishu ihari</h3>
        <h3>:(</h3>
      </div>

      <div class="card-rondera">
        <input class="Rondera" type="text" placeholder="Rondera ngaha..." v-model="searchText">

        <div v-for="(igisomwa, index) in filteredIbisomwa" :key="index">
          <ion-item>
            <ion-list>
              <ion-label>
                <h2 @click="$router.push(`/igisomwa/${igisomwa.id}`)">Igisomwa: {{ igisomwa.igisomwa }}</h2>
                <p>{{ igisomwa.kristo_mugisomwa.substring(0, 95) + "..." }}</p>
              </ion-label>
            </ion-list>
          </ion-item>
        </div>
      </div>


      <!-- <div class="card-parent-ntoya">
        <div v-for="(igisomwa, index) in filteredIbisomwa" :key="index">
          <div class="card-rondera">
            <h2 @click="$router.push(`/igisomwa/${igisomwa.id}`)">Igisomwa: {{ igisomwa.igisomwa }}</h2>
            <p>{{ igisomwa.kristo_mugisomwa.substring(0, 95) + "..." }}</p>
          </div>
          <br>
        </div>
      </div> -->


    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons } from '@ionic/vue';
import OurLoading from '@/components/OurLoading.vue'

export default {
  name: 'rondera-igisomwa',
  components: {
    IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons, OurLoading,
  },
  data() {
    return {
      searchText: "",
      loader: true,
    }
  },
  methods: {
    loadIntangamarara() {
      this.axios.get(this.$store.state.baseUrl + 'intangamarara/').then(
        response => {
          this.$store.state.intangamarara = response?.data?.results;
          this.loader = false;
        }
      ) //.finally(this.loadibibazo())
    },
    loadIbisomwa() {
      this.axios.get(this.$store.state.baseUrl + 'ibisomwa/').then(
        response => {
          this.$store.state.ibisomwa = response?.data?.results;
          // this.loader = false;
        }
      ) //.finally(this.loadIntangamarara())
    },

    topFunction() {
      // go to Top of the page 
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  },
  computed: {
    filteredIbisomwa() {
      return this.searchInArray(this.$store.state.ibisomwa, this.searchText) //searchInArray from mixin
    },
    filteredIntangamarara() {
      return this.searchInArray(this.$store.state.intangamarara, this.searchText) //searchInArray from mixin
    }
  },
  mounted() {
    this.loadIbisomwa();
    this.loadIntangamarara();
    this.topFunction()
  },
}
</script>


<style></style>