<template>
  <ion-menu content-id="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-title >Green Pastures</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>

      <router-link class="link-selah" :to="`/`">
        <ion-item detail='true'>
          <ion-label>Ikaze</ion-label>
      </ion-item>
    </router-link>

      <router-link class="link-selah" :to="`/about`">
          <ion-item detail='true'>
            <ion-label>Twebwe</ion-label>
          </ion-item>
      </router-link>

      <router-link class="link-selah" :to="`/ibibazo`">
          <ion-item detail='true'>
            <ion-label>Ibibazo</ion-label>
          </ion-item>
      </router-link>

      <router-link class="link-selah" :to="`/rondera`">
          <ion-item detail='true'>
            <ion-label>Rondera</ion-label>
          </ion-item>
      </router-link>

      <router-link class="link-selah" :to="`/shigikira`">
          <ion-item detail='true'>
            <ion-label>Shigikira</ion-label>
          </ion-item>
      </router-link>
      
      <router-link class="link-selah" :to="`/sangiza`">
          <ion-item detail='true'>
            <ion-label>Sangiza abandi</ion-label>
          </ion-item>
      </router-link>

      <ion-item>
          <!-- <ion-label>Impinduro 1.0.1</ion-label> --> <!-- ios -->
          <ion-label>Impinduro 1.0.2</ion-label> <!-- android -->
        </ion-item>

        <ion-item>
          <ion-label>Yakozwe na <a href="https://www.egeratech.com">egeraTech</a></ion-label>
        </ion-item>
        
    </ion-list>

        <br>
        <br>

  </ion-content>
</ion-menu>



<ion-page id="main-content">

<ion-header :translucent="true">
  <ion-toolbar>
    <ion-title>Shigikira</ion-title>
    <ion-buttons slot="end">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content :fullscreen="true">

 

    <div class="card-igisomwa">
        <h2>Shigikira igikorwa c'Imana</h2>
        <pre>Uhora uhezagirwa na Pastor NIKIZA Jean-Apôtre canke na green pastures urugendo rwo gusoma Bibiliya yose mumwaka wose??</pre>
        <pre>Urakunda ubutumwa bwiza kandi ushaka ko bwoshikira abatarashikirwa??</pre>
        <pre>Uhora usengera ishengero, umugeni wa Kristo kugira ashike kugusa na Kristo hamwe no kugutunganwa gose??</pre>
        <h3>Ukoresheje ifaranga ryawe shigikira Pastor NIKIZA Jean-Apôtre na green pastures witabire igikorwa gikomeye c'Imana ikomeye.</h3>
        <br>
            <a href="https://www.gofundme.com/f/donate-to-reach-more-people-with-the-gospel?utm_campaign=p_cp+share-sheet&utm_medium=copy_link_all&utm_source=customer" ><OurButton>GoFundMe</OurButton></a>
            <h4><a href="tel:+257 65281527">Lumicash: (+257)65281527</a></h4>
            <h4><a href="tel:+257 76780529">Pastor NIKIZA J-A Ecocash: (+257)76780529</a></h4>
            
            <h4><a href="tel:+257 76592886">Arielle NIKIZA +257 76592886</a></h4>

            <h4><a href="mailto:nik2flock@gmail.com">nik2flock@gmail.com <i class="icon-email" aria-hidden="true"></i></a></h4>
            <h4><a href="mailto:arielle2flock@gmail.com">arielle2flock@gmail.com <i class="icon-email" aria-hidden="true"></i></a></h4>
  
    </div>
    <br>
  <br>
  <br>
  <br>
</ion-content>
</ion-page>
</template>

<script>
import {     IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons } from '@ionic/vue';

import OurButton from '@/components/OurButton.vue'

export default {
  name: 'ShigikiraView',
  components: {
    IonPage, IonContent, IonTitle, IonMenuButton, IonToolbar, IonItem, IonLabel, IonHeader, IonList, IonMenu, IonButtons, OurButton
  },
}
</script>

<style>

</style>
