<template>

  <ion-app>

    <router-view />
    <!-- <SplashScreen/> -->
  </ion-app>
</template>

<script>
import { IonApp } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useBackButton, useIonRouter } from '@ionic/vue';
import { App } from '@capacitor/app';



export default defineComponent({
  name: 'App',
  components: {
    IonApp,
  
  },
  setup() {
    const ionRouter = useIonRouter();
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });
  }
});
</script>

<style>

</style>