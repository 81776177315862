import { createStore } from 'vuex'

export default createStore({
    state: {
        baseUrl: 'https://greenpastures.bi/api/',
        // baseUrl: 'http://127.0.0.1:8000/api/',
        igisomwaUnomusi: [],
        ibibazo: [],
        twebwe: [],
        ibisomwa: [],
        intangamarara: [],

    },
    mutations: {

    },
    
    actions: {
    },
    modules: {
    },
    getters:{
    },
    computed:{
    }
})






